import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { H4, ExternalHtml, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	Code,
	Header,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Toast } from "./toast";
import { useClearToast, useShowToast } from "./useToast";
import { ToastDropzone } from "./toast-dropzone";

export const Meta: DocPageMeta = {
	category: "Feedback",
	title: "Toast",
	notepad: "https://hackmd.io/gNBuFY4MTY2_YD-JPOiQDg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Toast"
			subTitle="A toast is a passive, non-modal notification that informs the user of changes or updates as a result of an action."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<Header.H2>Basic usage</Header.H2>
				<Paragraph>
					A <Code>Toast</Code> is displayed at the top of the page and remains there until it is
					dismissed by the user.
				</Paragraph>
				<Paragraph>
					There are four types of <Code>Toast</Code>: positive, neutral, warning, and negative.
					Using different types of <Code>Toast</Code> helps users understand what type of
					information is being communicated and how they should respond.
				</Paragraph>
				<H4>Positive</H4>
				<Paragraph>
					Let the user know their action was completed as expected. If the user just created
					something, allow them to view it. For example, “Policy template updated.” or “Email
					template updated.” Follow the writing guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#progress-and-congratulations">
						Progress and congratulations
					</a>
					.
				</Paragraph>
				<H4>Neutral</H4>
				<Paragraph>
					Provide the user with neutral and additional information. Make sure Toast has a clear
					purpose and supports (not impedes) the user. Be brief and provide only the information
					relevant to the task. For example, “The initial crawlers may take a few days to complete.
					Read scan process”. Follow the writing guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#help-content-for-complex-workflows">
						Help content for complex workflows
					</a>
					.
				</Paragraph>
				<H4>Warning</H4>
				<Paragraph>
					Make the user aware of a condition or potential problem with a warning message. For
					example, “Renew your subscription”. Avoid writing something that has already occurred.
					Instead, use a negative toast. Follow the writing guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#errors-and-warnings">
						Errors and warnings
					</a>
					.
				</Paragraph>
				<H4>Negative</H4>
				<Paragraph>
					Inform the user of an error or failure and possibly prevent the user from continuing until
					the problem is resolved. Point out the exact location of the problem - where the user
					should go and what steps are required to resolve the problem. For example, “Cannot add the
					file. The file already existed. Please upload again.” Follow the writing guideline for{" "}
					<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#issues-and-issue-descriptions">
						Issues and issue descriptions
					</a>
					.
				</Paragraph>

				<Example fn={ShowToastUsage} />
				<Header.H2>Clear toasts</Header.H2>
				<Paragraph>
					<Code>useClearToast</Code> is not a feature for end users. The user can clear the{" "}
					<Code>Toast</Code> by clicking the close button, or pressing escape. The{" "}
					<Code>Toast</Code> can also be cleared programmatically if you i.e. have a single-page
					application, where you want to clear the <Code>Toast</Code> on page navigation. To clear
					the <Code>Toast</Code> from anywhere use the <Code>useClearToast</Code> hook from anywhere
					in your code. Could be your routing framework, or in this case, an arbitrary button.
				</Paragraph>
				<Example fn={ClearToastExample} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					To provide users with a consistent experience, the <Code>Toast</Code> type has a
					corresponding color and icon. By default, <Code>Toast</Code> contains an icon, inline
					text, an optional link, and a close button.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Icon:</b> the colour and icon give the user a visual indication of the message at a
							glance.
						</>,
						<>
							<b>Inline text:</b> provide short and clear text that directly relates to the task at
							hand. See the <a href="#guidelines">writing guideline</a> below.
						</>,
						<>
							<b>Link:</b> an optional link that takes the user to a page with more details or an
							action that the user can perform.
						</>,
						<>
							<b>Close button:</b> closes the toast.
						</>,
					]}
				/>

				<Example fn={BasicUsage} />
				<Header.H2>Examples with links</Header.H2>
				<Paragraph>
					A <Code>Toast</Code> with a link allows the user to interact with the content and perform
					an action through a shortcut. This link should be short and navigate the user to a page or
					modal where the user can take action to resolve the issue or find more information. See{" "}
					<GatsbyLink to="/lib/components/Navigation/Link">Link</GatsbyLink> guideline. Use a{" "}
					<Code>Toast</Code> with a link as follows:
				</Paragraph>
				<Ul
					items={[
						<>Use only one link per toast.</>,
						<>Be clear about what action the user can take.</>,
						<>A link should only be a shortcut for the user.</>,
						<>Make sure that the user can reach the link in the message in other ways.</>,
					]}
				/>
				<H4>Support Undo</H4>
				<Paragraph>
					Remember that the user often takes an action by mistake. Allow the user to correct
					mistakes by displaying an “Undo” action. This promotes a sense of freedom and confidence
					for the user.
				</Paragraph>

				<Example fn={UsageWithLinks} />
				<Header.H2>Examples with external html</Header.H2>
				<Paragraph>
					If the user will{" "}
					<a href="https://fancy.siteimprove.com/lib/components/Navigation/Link#open-in-new-tab-or-window">
						open a new tab or window
					</a>{" "}
					with the link, use the style to match the user behavior. Follow{" "}
					<a href="#examples-with-links">Example with links</a>.
				</Paragraph>
				<Example fn={UsageWithExternalHtml} />
				<Header.H2>Toast dropzone</Header.H2>
				<Paragraph>
					<Code>ToastDropzone</Code> is not a feature for end users. It is a structural component
					that allows developers to control where the <Code>Toast</Code> component will appear in
					the Document Object Model (DOM).
				</Paragraph>
				<Paragraph>
					By default toasts are appended to the DOM in the bottom of {"<body>"}. ToastDropzone lets
					you to control where in the DOM the Toasts are rendered, allowing it to inherit styles, if
					you don't use a global stylesheet.
				</Paragraph>
				<Example fn={ToastDropzoneUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs component={Toast} initialProps={{ message: "Some message", type: "positive" }} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use the <Code>Toast</Code> when it is relevant to the user's goal and is presented in
						the context of what they are doing.
					</Paragraph>
					<Header.H4>Usage</Header.H4>
					<Paragraph>
						The <Code>Toast</Code> is typically used for the following purpose:
					</Paragraph>
					<Paragraph>
						<b>Feedback on the user’s intent</b>
					</Paragraph>
					<Paragraph>
						The main use case for the <Code>Toast</Code> is to notify users of actions or tasks they
						have initiated. For example, you might use a positive toast to notify a user that they
						have successfully deleted something because their intended action was successful. You
						could send a <Code>Toast</Code> if users
					</Paragraph>
					<Ul
						items={[
							<>Add a new feature</>,
							<>Change a setting</>,
							<>Delete a site</>,
							<>Edit a user permission</>,
						]}
					/>
					<Paragraph>
						An example of a <Code>Toast</Code> can be found in{" "}
						<a href="https://my2.siteimprove.com/Policy/16111846137/ContentPolicies/Index/1/ChangedDate/Desc?pinnedOnly=false&pageSize=200&policies-tab=0&lang=en-US">
							{"Main menu > Policy > My Policies > Edit policy"}
						</a>{" "}
					</Paragraph>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								The content format is set to plain text. It is not recommended to use any additional
								content format such as buttons, headings, paragraphs or lists.
							</>,
							<>
								A <Code>Toast</Code> should contain only a single line of inline text. In wide
								layouts, you should increase the width of the container to accommodate longer inline
								text. In a narrow layout, you should try to explain the text in three words. As
								mentioned in the writing guidelines below, you should limit words in toasts.
							</>,
						]}
					/>
					<Header.H4>Dismissal</Header.H4>
					<Ul
						items={[
							<>
								If a new <Code>Toast</Code> appears, the former <Code>Toast</Code> will
								automatically disappear.
							</>,
							<>
								By default, the <Code>Toast</Code> can only disappear if it is manually dismissed.
								This way, the <Code>Toast</Code> remains accessible to the user who needs more time
								to read the text or wants to refer back to the text.
							</>,
							<>
								Note that after the <Code>Toast</Code> disappears, the user can no longer access the
								message.
							</>,
							<>
								When a <Code>Modal</Code> is opened, a <Code>Toast</Code> should always be
								dismissed.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								containing high-priority information that requires their attention or action. Use{" "}
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> instead.
							</>,
							<>
								containing system or product level or medium-priority information. In this case, use{" "}
								<GatsbyLink to="/lab/components/Feedback/Message">Message</GatsbyLink> instead.
							</>,
							<>the information is not task-specific.</>,
							<>
								the user interacts with a form or modal to perform an action. In this case, use{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form Element Wrapper
								</GatsbyLink>{" "}
								to display a validation error or warning message.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							<>
								When including a link in the <Code>Toast</Code>, make sure it can stand on its own,
								and avoid generic text such as "Click here" or "Read more".
							</>,
							<>
								Avoid frequent interruptions with the <Code>Toast</Code>, especially for users with
								visual and cognitive disabilities.
							</>,
							<>
								Avoid displaying the <Code>Toast</Code> when the page loads.
							</>,
							<>
								When the <Code>Toast</Code> is manually closed, designers should decide where the
								keyboard focus should go and communicate this to developers. Ideally, the element
								with focus state should make it easier for users to reach their goals.
								<Ul
									items={[
										<>
											<b>Scenario 1:</b> whenever the user open a modal or a dialog box, the user
											should move the focus there. When the user close it again, the focus should be
											moved back to the button that opened it.
										</>,
										<>
											<b>Scenario 2:</b> if clicking a “Delete This Item” button also removes the
											button itself, you should consider where it makes sense to place the focus.
											Read the guideline for{" "}
											<a href="https://siteimprove-wgs.atlassian.net/l/cp/JR5Ur4gf">
												managing focus
											</a>
											.
										</>,
									]}
								/>
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[
							<>
								Make sure that the keyboard focus moves to a meaningful place when the toast is
								closed manually. Note that this decision should be discussed with designers.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								Limit the text to one line for all types of <Code>Toast</Code>.
							</>,
							<>Be clear, concise, and easy to understand.</>,
							<>
								Use the {"{noun} + {verb} "} form. For example, write "Tags updated" rather than
								"Your tags have been updated". The extra words are not necessary because the user
								already understands the context.
							</>,
							<>Remember that the text may become longer when translated.</>,
							<>Avoid technical jargon.</>,
							<>Use sentence case for inline text and end a sentence with a period.</>,
							<>Avoid unnecessary words and articles such as "the," "an" or "a".</>,
							<>
								Use the Siteimprove voice. Be capable, personable, insightful, and clear and not
								system-centric. Follow the{" "}
								<a href="https://fancy.siteimprove.com/Writing/tone-of-voice">Tone Of the Voice</a>.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const ShowToastUsage = (): JSX.Element => {
	const showToast = useShowToast();
	return (
		<>
			<button
				aria-haspopup="dialog"
				onClick={() => showToast({ message: "Positive", type: "positive" })}
			>
				Positive
			</button>
			<button
				aria-haspopup="dialog"
				onClick={() => showToast({ message: "Neutral", type: "neutral" })}
			>
				Neutral
			</button>
			<button
				aria-haspopup="dialog"
				onClick={() => showToast({ message: "Warning", type: "warning" })}
			>
				Warning
			</button>
			<button
				aria-haspopup="dialog"
				onClick={() => showToast({ message: "Negative", type: "negative" })}
			>
				Negative
			</button>
		</>
	);
};
/** @ignore [visual] */
const ToastDropzoneUsage = () => <ToastDropzone />;

const BasicUsage = () => (
	<>
		<Toast type="positive" message="Some positive message" />
		<Toast type="neutral" message="Some neutral message" />
		<Toast type="warning" message="Some warning message" />
		<Toast type="negative" message="Some negative message" />
	</>
);

const UsageWithLinks = () => (
	<Toast
		type="positive"
		message={
			<>
				Some positive message with a <a href="#examples-with-links">link</a>
			</>
		}
	/>
);

const UsageWithExternalHtml = () => (
	<Toast
		type="positive"
		message={
			<ExternalHtml>
				{"Some positive message with a <a href='#examples-with-external-html'>link</a>"}
			</ExternalHtml>
		}
	/>
);

const ClearToastExample = () => {
	const clearToast = useClearToast();
	return <button onClick={clearToast}>Clear toasts programatically</button>;
};
